import { FluentProvider, makeStyles } from '@fluentui/react-components';
import { Outlet } from 'react-router-dom';
import { useTheme } from '../theme/theme';
import { ToastProvider } from '../theme/toast';

const theme = useTheme();
const useStyles = makeStyles({
	container: {
		width: '100%',
		display: 'flex',
		flexGrow: 1
	},
});

const Layout = ({children}) => {
	const styles = useStyles();

	return (
		<FluentProvider theme={theme.lightTheme} className={styles.container}>
			<ToastProvider prefix={'cc-caller'}>
				<Outlet />
			</ToastProvider>
		</FluentProvider>
	);
};

export default Layout;
