import axios from 'axios';

const interceptor = { token: null, correlationKey: null };

// Function to set up the interceptor
export const setupInterceptor = (token, tokenParsed, correlationKey) => {
  
  interceptor.token = token;
  interceptor.tokenParsed= tokenParsed;
  interceptor.correlationKey = correlationKey;
  
};

export const createAxios = (baseUrl) => { 
  const axiosInstance = axios.create({ baseURL: baseUrl  });

  // Function to set up the interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // Include the Bearer token in the Authorization header
      if (interceptor.token) {
        config.headers['Authorization'] = `Bearer ${interceptor.token}`;
        config.headers['User-Id'] = interceptor.tokenParsed.sub;
      }

      // Include the correlationKey in the headers
      config.headers['correlationKey'] = interceptor.correlationKey;

      return config;
    },
    (error) => {
      // Handle the error
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}