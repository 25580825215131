
import { Button, tokens } from "@fluentui/react-components";

const CallTypeFilter = ({ callType, setCallType }) => {

    const button = (type) => {
        return (
            <Button appearance={callType == type ? 'primary' : 'transparent'} size="small" shape="circular" onClick={()=> setCallType(type)} >{type}</Button>
        );
    }

    return (
        <div style={{display: 'flex', gap: tokens.spacingHorizontalS}}>
            {button('All')}
            {button('Incoming')}
            {button('Outgoing')}
            {button('Missed')}
        </div>
    );
};

export default CallTypeFilter;