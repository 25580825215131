import { IdPrefixProvider } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import i18n from './i18n';
import { setupInterceptor } from './interceptor/axios.interceptor';
import Layout from './layout/Layout';
import Calls from './pages/Calls';

const router = createBrowserRouter([
	
	{ path: "", index: true, element: <Navigate to="calls" replace /> },
	{
		path: '/calls',
		element: <Layout />,
		children: [
			{ path: ':category?', index: true, element: <Calls /> }
		],
	},
	{
		path: '*',
		element: <div>Not Found</div>,
	},
]);

export default function Root(props) {

	const { keycloak, correlationKey } = props;
	
	useEffect(() => {
		setupInterceptor(keycloak.token, keycloak.tokenParsed, correlationKey);
	  }, [keycloak, correlationKey]);

	return (
		<IdPrefixProvider value="cc-caller">
			<I18nextProvider i18n={i18n}>
				<RouterProvider router={router} />
			</I18nextProvider>
		</IdPrefixProvider>
	);
}
