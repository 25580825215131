import { Avatar, Caption1, makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import CallIcon from './CallIcon';
import { useFormattedDuration } from '../hooks/useFormattedDuration';

const useStyles = makeStyles({
	entry: {
		display: 'flex',
		
		alignItems: 'center',
		marginBottom: 10, 
		gap: tokens.spacingHorizontalM,
		':hover': {
			cursor: 'pointer',
		},
	},
});

const CallsHistoryListItem = ({ call }) => {
	const classes = useStyles();
    const time = new Date(call.startTime);
	const highlight = call.type === 'Missed' ? tokens.colorPaletteRedForeground1 : tokens.colorNeutralForeground1;
	const duration = useFormattedDuration(+call.duration);
    
    return (
        <div className={classes.entry}>
			
			<Avatar name={call.contact.displayName } size={32} />
			<div style={{ flexGrow: 1 }}>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					{call.contact.displayName || call.contact.phoneNumber}
                    <Caption1  style={{display: 'flex', alignItems: 'center', color: highlight}}><CallIcon type={call.direction} /> {call.direction}</Caption1>
				</div>
			</div>
            <div style={{ flex: '0 0 100px', textAlign: 'right' }}>
                {duration}
            </div>
            <div style={{ flex: '0 0 100px', textAlign: 'right' }}>
                {time.toLocaleDateString()}
            </div>
		</div>
    );
};

export default CallsHistoryListItem;