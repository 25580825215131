import { makeStyles, tokens } from '@fluentui/react-components';
import CallsHistoryListItem from "./CallsHistoryListItem";

const useStyles = makeStyles({
	container: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalM,
	},
});

const CallsHistoryList = ({calls}) => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            {calls.map((call) => (
                <CallsHistoryListItem key={call.startTime} call={call} />
            ))}
        </div>
    );
};

export default CallsHistoryList;