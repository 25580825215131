import { createAxios } from "../interceptor/axios.interceptor";

class CallService {
    constructor(baseURL) {
      this.api = createAxios(baseURL || 'https://ccs-dev-api.freston.io');
    }

    async getCallHistory(callType, page, size) {
        
        if(!page)
            page = 0;

        if(!size)
            size = 20;

        const query = {
            result: callType.toLowerCase() === 'all' ? '' : callType,
            page: page,
            size: size
        };

        try {
            const response = await this.api.get(`/me/call-history`, { params: query });
            return response.data;
          } catch (error) {
            console.error(`Error fetching contact types:`, error);
            throw error;
          }
          
        // await new Promise(resolve => setTimeout(resolve, 2000));

        // const data  = [
        //     {
        //         id: '1',
        //         caller: { name: 'John Doe', number: '+1234567890' },
        //         time: '2023-10-02T10:00:00Z',
        //         duration: '5m 30s',
        //         type: 'Incoming',
        //         status: 'Completed'
        //     },
        //     {
        //         id: '2',
        //         caller: { name: 'Jane Smith', number: '+0987654321' },
        //         time: '2023-10-01T11:00:00Z',
        //         duration: '2m 15s',
        //         type: 'Outgoing',
        //         status: 'Completed'
        //     },
        //     {
        //         id: '3',
        //         caller: { name: 'Alice Johnson', number: '+1122334455' },
        //         time: '2023-10-01T12:00:00Z',
        //         duration: '0m 0s',
        //         type: 'Missed',
        //         status: 'Missed'
        //     },
        //     {
        //         id: '4',
        //         caller: { name: 'Bob Brown', number: '+2233445566' },
        //         time: '2023-09-30T09:00:00Z',
        //         duration: '3m 45s',
        //         type: 'Incoming',
        //         status: 'Completed'
        //     },
        //     {
        //         id: '5',
        //         caller: { name: 'Charlie Davis', number: '+3344556677' },
        //         time: '2023-09-29T08:30:00Z',
        //         duration: '1m 20s',
        //         type: 'Outgoing',
        //         status: 'Completed'
        //     },
        //     {
        //         id: '6',
        //         caller: { name: 'Eve Miller', number: '+4455667788' },
        //         time: '2023-09-28T07:45:00Z',
        //         duration: '0m 0s',
        //         type: 'Missed',
        //         status: 'Missed'
        //     }
        // ];

        // const filteredData = data.filter(call => callType === 'All' || call.type === callType);
        
        // return { data: filteredData };
    }
}

export default new CallService();