import { Stack, StackItem } from '@fluentui/react';
import {
	Button,
	Divider,
	makeStyles,
	SkeletonItem,
	Subtitle1,
	Body1Strong,
	tokens,
} from '@fluentui/react-components';
import { TextSortAscending16Regular, TextSortDescending16Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import callService from '../services/callService';
import CallsHistoryList from './CallsHistoryList';
import CallTypeFilter from './CallTypeFilter';

const skeletonStyle = makeStyles({
	firstRow: {
		alignItems: 'center',
		display: 'flex',
		boxSizing: 'border-box',
		gap: '10px',
	},
	circle: {
		flex: '0 0 40px',
	},
});

const useStyle = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingVerticalL,
	},
});

const SkeletonLoader = () => {
	const styles = skeletonStyle();
	return (
		<Stack tokens={{ childrenGap: 10 }}>
			{[...Array(5)].map((_, index) => (
				<StackItem key={index} className={styles.firstRow}>
					<SkeletonItem shape="circle" size={40} className={styles.circle} />
					<SkeletonItem size={16} style={{ flexGrow: 1 }} />
				</StackItem>
			))}
		</Stack>
	);
};
const CallsHistoryFilter = () => {
	const [calls, setCalls] = useState([]);
	const [sorted, setSorted] = useState([]);
	const [callType, setCallType] = useState('All');
	const [sort, setSort] = useState('asc');
	const [loading, setLoading] = useState(true);
	const style = useStyle();

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			setCalls([]);
			try {
				const response = await callService.getCallHistory(callType);
				setCalls(response);
			} catch (error) {
				console.error('Error fetching contacts:', error);
			} finally {
				setLoading(false);
			}
		};

		fetch();
	}, [callType]);

	useEffect(() => {
		if(!calls.length){
			setSorted([]);
			return;
		}

		let newSort = [];
		
		if (sort === 'asc') {
			newSort = calls.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
		} else {
			newSort = calls.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
		}

		//clone array to trigger rerender
		setSorted([...newSort]);
	}, [sort, calls]);

	useEffect(() => {
		console.log('sorted', sort, sorted);
	}, [sort, sorted]);
	

	return (
		<div className={style.container}>
			<div
				style={{
					display: 'flex',
					gap: tokens.spacingHorizontalM,
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ display: 'flex', gap: tokens.spacingHorizontalS }}>
					<Subtitle1>History</Subtitle1>
				</div>

				<div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
					<CallTypeFilter callType={callType} setCallType={setCallType} />
				</div>

				{sort === 'asc' ? (
					<Button
						appearance='transparent'
						size="small"
						icon={<TextSortAscending16Regular />}
						onClick={() => setSort('desc')}
					></Button>
				) : (
					<Button
						appearance='transparent'
						size="small"
						icon={<TextSortDescending16Regular />}
						onClick={() => setSort('asc')}
					></Button>
				)}
			</div>
			<Divider />
			{loading && <SkeletonLoader /> }
			{!loading && sorted.length > 0 && <CallsHistoryList calls={sorted} />}
			{!loading && sorted.length < 1 && <Body1Strong>There is no call history</Body1Strong>}
		</div>
	);
};

export default CallsHistoryFilter;
