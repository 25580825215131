import { CallInbound16Regular, CallMissed16Regular, CallOutbound16Regular } from '@fluentui/react-icons';

const CallIcon = ({type}) => {

    if(type === 'Incoming'){
        return <CallInbound16Regular />;
    }
    else if(type === 'Outgoing'){
        return <CallOutbound16Regular />;
    }
    else if(type === 'Missed'){
        return <CallMissed16Regular />;
    }

    return null;
};

export default CallIcon;
