import { useMemo } from 'react';
import { intervalToDuration, formatDuration } from 'date-fns';

export const useFormattedDuration = (seconds) => {
	return useMemo(() => {
		if (!seconds) return '--';

		const duration = intervalToDuration({
			start: 0,
			end: seconds * 1000, // Convert seconds to milliseconds
		});

		// Format the duration
		const formatted = formatDuration(duration, {
			format: ['hours', 'minutes', 'seconds'],
			delimiter: ' ',
		});

		// Replace words with abbreviations
		return formatted
			.replace(/ hours?/, 'h')
			.replace(/ minutes?/, 'm')
			.replace(/ seconds?/, 's');
	}, [seconds]);
};
