import {
	makeStyles,
	tokens
} from '@fluentui/react-components';
import CallsHistoryFilter from '../components/CallsHistoryFilter';

const useStyle = makeStyles({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingVerticalL,
		padding: tokens.spacingHorizontalL,
		borderRight: `2px solid ${tokens.colorNeutralBackground1Selected}`
	},
});

const Index = () => {
	const style = useStyle();

	//get
	return (
		<div className={style.container}>
			<CallsHistoryFilter />

		</div>
	);
};

export default Index;
