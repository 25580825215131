import { Toast, ToastBody, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import React from "react";

const useToast = () => {

    const toasterId = React.useContext(ToastContext);
    const { dispatchToast } = useToastController(toasterId);


    const toast = (title, message, type) => {
      console.log("toast", title, message, type, toasterId);
        dispatchToast(
            <Toast>
              <ToastTitle>{title}</ToastTitle>
              <ToastBody subtitle="Subtitle">{message}</ToastBody>
            </Toast>,
            { intent: type }
          );
    };


    return toast;
};

export const ToastContext = React.createContext('toaster-id');

export const ToastProvider = ({prefix, children}) => {

  const toasterId = useId(prefix);
  console.log("toasterId", toasterId);

  return (
    
    <ToastContext.Provider value={toasterId}>
      {children}
      <Toaster toasterId={toasterId} />
    </ToastContext.Provider>
  );
};



export default useToast;